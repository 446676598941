import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { configurationService } from "@services/configApp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EsfirusButton from "../../../../components/ui/Button/EsfirusButton";
import EsfirusTextInput from "../../../../components/ui/Text-input/EsfirusTextInput";
import { Obra, ObraStatus } from "../../../../models/obra";
import "./ModalNewObra.scss";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import { getAvailableCompanies, getInternalCompany, getInternalReference, getReferenceByCompany } from "@helpers/companyData";
import alertIcon from "@assets/svgs/alert-triangle-outline.svg";
import { checkResponsableObraIsIntern } from "@screens/NewParte/helpers";
import useDebounce from "@services/hooks/useDebounce";

interface ModalNewObraProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectObra: (obra: Obra) => Promise<void>;
  onlyInternalCompany?: boolean;
}

function ModalNewObra({ open, setOpen, selectObra, onlyInternalCompany = false }: ModalNewObraProps) {
  const dispatch = useDispatch();
  const obras = useSelector((state) => (state as any).obras).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const {
    filtroObraShowEmpresa,
    filtroObraShowReferencia,
    filtroObraShowResponsable,
    filtroObraShowCliente,
    allowObraWithoutResponsable
  } = configurationService.getConfigBuscadorObra();

  const { autoRefParte } =
    configurationService.getConfigCabeceraParte();

  const [selectedEmpresa, setSelectedEmpresa] = useState(getInternalCompany());
  const [selectedCliente, setSelectedCliente] = useState('');
  const [empresasDisponibles, setEmpresasDisponibles] = useState<any>([]);
  const [empresasList, setEmpresasList] = useState<any>([]);
  const [obrasList, setObrasList] = useState([]);

  const timeDebounce = 1000
  const [filterDescription, setFilterDescription] = useState('');
  const [filterObra, setFilterObra] = useState('');
  const [filterReferencia, setFilterReferencia] = useState('');
  const [filterResponsable, setFilterResponsable] = useState('');
  const [filterCliente, setFilterCliente] = useState('');
  // Debounce values
  const debouncedFilterDescription = useDebounce(filterDescription, timeDebounce);
  const debouncedFilterObra = useDebounce(filterObra, timeDebounce);
  const debounceFilterReferencia = useDebounce(filterReferencia, timeDebounce);
  const debounceFilterResponsable = useDebounce(filterResponsable, timeDebounce);
  const debounceFilterCliente = useDebounce(filterCliente, timeDebounce);


  const [currentObra, setCurrentObra] = useState<Obra>({
    name: "",
    description: "",
    obraCode: 0,
    reference: "",
    calRef: "",
    responsible: 0,
    status: ObraStatus.OPEN,
    company: 0,
    companyDestino: 0,
    Administracion: false,
    SolActividad: false,
    SolCapitulo: false,
    SolPresupuesto: false,
  });

  useEffect(() => {
    let companies: any[] | "*" = []
    if (onlyInternalCompany) companies = [getInternalCompany()]
    else companies = getAvailableCompanies(true)
    setEmpresasDisponibles(companies)
  }, [])

  useEffect(() => {
    setEmpresasList(empresas.filter((e: any) => empresasDisponibles.includes(e.Empresa) || empresasDisponibles == "*"));

  }, [empresasDisponibles, selectedEmpresa])

  useEffect(() => {
    const collectData = async () => {
      const idEmpleado = getInternalReference()
      const idEmpresaBase = getInternalCompany()
      const estados = ["EC", "GA"]
      const obrasDef = obras?.
        filter((obra: any) => selectedEmpresa !== '' ? (obra.Empresa == selectedEmpresa) : obra)?.
        filter((obra: any) => filterDescription !== '' ? obra?.Descripcion?.toLowerCase()?.includes(filterDescription.toLowerCase()) : obra)?.
        filter((obra: any) => filterObra !== '' ? obra?.Obra?.toString()?.toLowerCase()?.includes(filterObra?.toLowerCase()) : obra)?.
        filter((obra: any) => filterReferencia !== '' ? obra?.Referencia?.toLowerCase()?.includes(filterReferencia.toLowerCase()) : obra)?.
        filter((obra: any) => filterResponsable !== '' ? obra?.Responsable?.toString()?.toLowerCase()?.includes(filterResponsable?.toLowerCase()) : obra)?.
        filter((obra: any) => filterCliente !== '' ? obra?.NombreCliente?.toString()?.toLowerCase()?.includes(filterCliente?.toLowerCase()) : obra)?.
        filter((obra: any) => !obra?.EmpresaDestino && estados.includes(obra.Estado))?.
        map((el: any) => {
          const empresa = empresas?.find((e: any) => e.Empresa === el.Empresa);
          return {
            id: el.id,
            prefixId: el.prefixId,
            name: el.NombreCliente,
            nombreCliente: el.NombreCliente,
            codigoCliente: el.Cliente,
            description: el.Descripcion,
            obraCode: el.Obra,
            delegacion: el.Delegacion,
            obraExternaCode: el.ObraExterna,
            empleado: getReferenceByCompany(el.Empresa),
            empleadoExterno: idEmpresaBase != el.Empresa ? idEmpleado : null,
            reference: autoRefParte == "codEmpleado"
              ? getReferenceByCompany(el.Empresa)
              : autoRefParte == "codReferencia"
                ? el.Referencia
                : ""
            ,
            referenciaExterna:
              autoRefParte == "codReferencia"
                ? el.Referencia
                : idEmpresaBase != el.Empresa
                  ? idEmpleado
                  : "",
            responsible: el.Responsable,
            status: el.Estado,
            company: el.Empresa,
            companyDestino: idEmpresaBase != el.Empresa ? idEmpresaBase : null,
            Administracion: el.Administracion,
            date: el.Fecha,
            calRef: el.Referencia,
            SolActividad: el.SolActividad,
            SolCapitulo: el.SolCapitulo,
            SolPresupuesto: el.SolPresupuesto,
            duplicated: el.duplicated,
            descripcion_list: `${el?.Obra} - ${el?.Descripcion}`,
            tipo_obra: el.Tipo
          };
        });

      const promises = obrasDef?.map((obra: any) => checkResponsableObraIsIntern(obra)) ?? []
      const checksResponsable = await Promise.all(promises)

      const resultObras = obrasDef?.map((obra: any, index: any) => ({
        ...obra,
        checkResponsable: checksResponsable[index]
      }))

      setObrasList(resultObras)
    }

    collectData()
  }, [selectedEmpresa, debounceFilterCliente, obras, debouncedFilterDescription, debouncedFilterObra, debounceFilterReferencia, debounceFilterResponsable])

  const acceptClose = async () => {
    await selectObra(currentObra);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-obra-modal">
        <div className="header">
          <h5 className="text-center">Selecciona la obra</h5>
        </div>
        <div className="body">
          <p className="text-center-mobile">
            Filtra el listado de obras mediante los siguientes campos:
          </p>

          <Grid container spacing={4} md={12} >
            {
              filtroObraShowEmpresa &&
              <Grid item md={6} xs={12}>
                <label >Empresa</label>
                <div className="selectWrap">
                  <EsfirusSelect
                    options={empresasList.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
                    value={selectedEmpresa}
                    change={(e: any) => {
                      let val = !!e ? e : 10000000
                      setSelectedEmpresa(val);
                    }}
                    placeholder="Seleccionar empresa"></EsfirusSelect>
                </div>
              </Grid>
            }
            <Grid item md={6} xs={12}>
              <label>Descripción</label>
              <EsfirusTextInput
                value={filterDescription || ""}
                onChange={(e: any) =>
                  setFilterDescription(e.target.value)
                }
                placeholder="Descripción"
                fullWidth
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <label >Código obra</label>
              <EsfirusTextInput
                value={filterObra || ""}
                onChange={(e: any) =>
                  setFilterObra(e.target.value)
                }
                placeholder="Código obra"
                fullWidth
              />
            </Grid>


            {
              filtroObraShowReferencia && (
                <Grid item md={6} xs={12}>
                  <label >Referencia</label>
                  <EsfirusTextInput
                    value={filterReferencia}
                    onChange={(e: any) => {
                      setFilterReferencia(e.target.value)
                    }}
                    placeholder="Referencia"
                    fullWidth
                  />
                </Grid>
              )}

            {
              filtroObraShowResponsable && (
                <Grid item md={6} xs={12}>
                  <label >Responsable</label>
                  <EsfirusTextInput
                    value={filterResponsable}
                    onChange={(e: any) =>
                      setFilterResponsable(e.target.value)
                    }
                    placeholder="Responsable"
                    fullWidth
                  />
                </Grid>
              )}

            {filtroObraShowCliente &&
              <Grid item md={6} xs={12}>
                <label >Cliente</label>
                <div className="selectWrap">

                  <EsfirusTextInput
                    value={filterCliente}
                    onChange={(e: any) =>
                      setFilterCliente(e.target.value)
                    }
                    placeholder="Cliente"
                    fullWidth
                  />
                </div>
              </Grid>
            }

          </Grid>

          <Grid container spacing={4} md={12}  >
            <Grid className="mt-20" item md={12} xs={12}>

              <div className="obra-container">
                <div className="scroll">
                  {obrasList.map((obra: any, index: any) => {
                    const isSelected = currentObra.obraCode === obra.obraCode
                    const isDuplicated = obra.duplicated
                    const hasResponsable = obra.checkResponsable
                    const showErrorFormat = isDuplicated || !hasResponsable
                    const isSelectable = !isDuplicated && (allowObraWithoutResponsable || hasResponsable)

                    return (
                      <div
                        key={`${obra.name}-${obra.reference}-${index}`}
                        className={`obra-item${isSelected ? " selected" : ""}${showErrorFormat ? " duplicated" : ""}${isSelectable ? " selectable" : ""}`}
                        onClick={() => {
                          if (isSelectable) setCurrentObra(obra)
                        }}
                        onDoubleClick={async () => {
                          if (isSelectable) await acceptClose()
                        }}
                      >
                        {obra.descripcion_list || "--"}
                        {isDuplicated ? <span><img src={alertIcon} /> Existe mas de una obra en empresaDestino en el mismo año</span> : <></>}
                        {!hasResponsable ? <span><img src={alertIcon} /> No tiene un responsable interno.</span> : <></>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>

          </Grid>

          <div className="button-container">
            <EsfirusButton
              onClick={async () => await acceptClose()}
              fullWidth
              color="secondary"
            >
              Aceptar
            </EsfirusButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNewObra;
