import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Modal, Paper } from "@mui/material"
import FormPedido, { FormDataPedido } from "@components/FormParte/FormPedido"
import { pedidosService } from "@services/pedidos"
import "./PedidoIndividual.scss"
import Loader from "@components/ui/Loader/Loader"
import { useDispatch } from "react-redux"
import { addLine, updateLine } from "@store/slices/lines"

const PedidoIndividual = ({ isEditing = false }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [initialData, setInitialData] = useState<FormDataPedido | null>(null)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    let componentMounted = true

    if (id) {
      const fetchPedido = async () => {
        setIsLoadingData(true)
        try {
          const resp = await pedidosService.get({ unique_id: id })
          if (componentMounted) setInitialData(resp?.data?.data)
        } catch {
          handleClose()
        } finally {
          if (componentMounted) setIsLoadingData(false)
        }
      }

      fetchPedido()
    }

    return () => {
      componentMounted = false
    }
  }, [id])

  const handleClose = () => {
    navigate("/pedidos")
  }

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-pedido-title"
      aria-describedby="modal-modal-pedido-description"
    >
      <div className="see-modal">
        <Paper
          elevation={3}
          sx={{
            maxWidth: 1048,
            margin: "auto",
            borderRadius: "25px",
            paddingBottom: "30px",
          }}
        >
          {isLoadingData ? (
            <Box className="ml-20 mr-20 mt-40">
              <Loader message="Cargando datos del pedido" />
            </Box>
          ) : (
            <>
              <div className="paper-header">
                {!isEditing ? (
                  <h5>Nº Pedido {initialData ? initialData.purchase_order_id : ""}</h5>
                ) : (
                  <h5>Editar Nº Pedido {initialData ? initialData.purchase_order_id : ""}</h5>
                )}
              </div>
              <Box className="ml-20 mr-20 mt-40">
                <FormPedido
                  initialData={initialData}
                  handleClose={handleClose}
                  editable={isEditing}
                  isView={!isEditing}
                />
              </Box>
            </>
          )}
        </Paper>
      </div>
    </Modal>
  )
}

export default PedidoIndividual